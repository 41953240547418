import { getTwsStickyLQ, getSourceInfo } from '../config';
import { store } from '../reducers/configure-store';
import { getWidgetHost } from './lqServiceUtil';

export const getFlowName = (stateInfo, fiosDetailsStore) => {
  let flowName = 'lq widget';
  if (stateInfo.addressQualification && stateInfo.addressQualification.lqResult && stateInfo.addressQualification.lqResult.preOrderInServiceDate) {
    flowName = stateInfo.loopQualApp && stateInfo.loopQualApp.isLoggedIn ? '5g_preorder_aal' : '5g_preorder_nse';
  } else if (fiosDetailsStore && fiosDetailsStore.vendorDetails && fiosDetailsStore.vendorDetails.vendor_name) {
    const vendorFlowName = fiosDetailsStore.vendorDetails.vendor_name;
    if (vendorFlowName === 'Clearlink') {
      flowName = 'lq widget + cl';
    } else if (vendorFlowName === 'RedVentures' || vendorFlowName === 'RVMymoveCart' || vendorFlowName === 'RVMYMVCART') {
      flowName = 'lq widget + rv';
    } else if (vendorFlowName === 'Target') {
      flowName = 'lq widget + target';
    } else if (vendorFlowName === 'Quology') {
      flowName = 'lq widget + Qo';
    } else if (vendorFlowName === 'VWCART') {
      flowName = 'lq widget + Victra';
    } else if (vendorFlowName === 'OfflineToOnline') {
      flowName = 'lq widget + O2O';
    } else {
      flowName = `lq widget + ${vendorFlowName}`;
    }
  } else if (stateInfo.loopQualApp && stateInfo.loopQualApp.TWS) {
    flowName = `lq widget|${getTWSName(stateInfo.loopQualApp.TWS)}`;
  } else if (stateInfo.loopQualApp && stateInfo.loopQualApp.isACPFlow) {
    flowName = `lq widget|vf`;
  } else if (stateInfo.loopQualApp && stateInfo.loopQualApp.newConnection) {
    flowName = `lq widget|daw`;
  }
  return flowName;
};

export const getTWSName = (tws_name) => {
  let tws = tws_name;
  if (tws) {
    if (tws === 'medical_new') {
      tws = 'nurses';
    } else if (tws === 'responder') {
      tws = 'first responders';
    } else if (tws === 'milvet') {
      tws = 'military';
    } else {
      tws = `${tws}s`;
    }
  }
  return tws;
};
export const populatelqStateValue = (lqState, qualify) => {
  const productsQualifiedArr = [];
  let productsQualified = '';
  try {
    if (qualify === 'fios') {
      productsQualified = 'nc-single-';
      const srvcs = lqState && lqState.fiosQualification && lqState.fiosQualification.services;
      if (srvcs) {
        for (let i = 0; i < srvcs.length; i++) {
          if (srvcs[i].qualified === 'Y' && srvcs[i].servicename === 'FiOSData') {
            productsQualified += 'fdata';
          } else if (srvcs[i].qualified === 'Y' && srvcs[i].servicename === 'Video') {
            productsQualified += productsQualified !== 'single-' ? '+ftv' : 'ftv';
            productsQualified = productsQualified.replace('single', 'double');
          } else if (srvcs[i].qualified === 'Y' && srvcs[i].servicename === 'Voice') {
            productsQualified += productsQualified !== 'double-' || productsQualified !== 'single' ? '+fphone' : 'fphone';
            productsQualified =
              productsQualified.indexOf('single') > -1
                ? productsQualified.replace('single', 'double')
                : productsQualified.replace('double', 'triple');
          }
        }
      }
      let type = 'single';
      if (productsQualified.indexOf('double') > -1) {
        type = 'double';
      } else if (productsQualified.indexOf('triple') > -1) {
        type = 'triple';
      }
      productsQualifiedArr.push(qualify, type);
      if (productsQualified.indexOf('fdata') > -1) {
        productsQualifiedArr.push('fdata');
      }
      if (productsQualified.indexOf('fphone') > -1) {
        productsQualifiedArr.push('fphone');
      }
      if (productsQualified.indexOf('ftv') > -1) {
        productsQualifiedArr.push('ftv');
      }
    } else if (qualify === 'lte') {
      productsQualifiedArr.push('lte');
    } else if (qualify === '5g') {
      productsQualifiedArr.push('5g', lqState.qualifiedCBand ? 'cband' : 'mmWave');
    }
    return productsQualifiedArr;
  } catch (e) {
    console.log('populatelqStateValue');
  }
};
export const getStoreInfo = () => {
  const getStoreData = store && store.getState() ? store.getState() : '';
  const stateInfo = {
    loopQualApp: getStoreData && getStoreData.loopQualApp ? getStoreData.loopQualApp : '',
    fiosDetails: getStoreData && getStoreData.fiosDetails ? getStoreData.fiosDetails : '',
    notify: getStoreData && getStoreData.notify ? getStoreData.notify : '',
    addressQualification: getStoreData && getStoreData.addressQualification ? getStoreData.addressQualification : '',
    customerStreetDetails:
      getStoreData && getStoreData.addressLookup && getStoreData.addressLookup.customerStreetDetails
        ? getStoreData.addressLookup.customerStreetDetails
        : '',
    bulkQualState: getStoreData && getStoreData.bulkQualDetails ? getStoreData.bulkQualDetails : '',
  };
  return stateInfo;
};
export const getDevice = () => {
  let device = 'eordering';
  if (/android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())) {
    device = 'mobile';
  } else if (/Tablet|iPad/i.test(navigator.userAgent.toLowerCase())) {
    device = 'eordering tablet';
  } else {
    device = 'eordering';
    if (/android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())) {
      device = 'mobile';
    } else if (/Tablet|iPad/i.test(navigator.userAgent.toLowerCase())) {
      device = 'eordering tablet';
    } else {
      device = 'eordering';
    }
  }
  return device;
};

export const getSubCategory = (renderSetting, stateInfo) => {
  let subCategory = '';
  const plansvisit = window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit';
  if (stateInfo && stateInfo.loopQualApp.homeLQ) subCategory = 'LQ 5.0';
  else if ((stateInfo && stateInfo.loopQualApp.isResponsiveLQ) || getSourceInfo() === 'ResponsiveLQ' || getTwsStickyLQ() === 'Y' || plansvisit)
    subCategory = 'LQ 4.0 responsive';
  else if (renderSetting && renderSetting.miniLq && renderSetting.miniLq.enabled) subCategory = 'LQ 4.0';
  else if (
    stateInfo?.loopQualApp?.TWS ||
    stateInfo?.loopQualApp?.isACPFlow ||
    stateInfo?.loopQualApp?.newConnection ||
    stateInfo?.loopQualApp?.isComboMFJT
  )
    subCategory = 'LQ 3.0 responsive';
  else subCategory = 'LQ 3.0';
  return subCategory;
};

export const getChannelName = (stateInfo = {}) => {
  const widgetHost = getWidgetHost(window.location.pathname);
  return stateInfo.loopQualApp.isComboMFJT ||
    stateInfo.loopQualApp.isECComboMFJT ||
    stateInfo.loopQualApp.sourceInfo?.toUpperCase() === 'CPC' ||
    stateInfo.loopQualApp.sourceInfo === 'digitalCombo' ||
    (widgetHost && widgetHost === '5g')
    ? 'VZW'
    : 'VZF';
};
