const getHostName = () => {
  const url = window.location.href;
  if (url.includes('localhost') || url.includes('127.0.0.1')) {
    return 'http://localhost:4002'; // localhost can be changed according to local port utilised for hosting.
  }
  if (
    window.location.hostname.includes('vzwqa') ||
    window.location.hostname.includes('aws') ||
    window.location.hostname.includes('preprod') ||
    window.location.hostname.includes('98')
  ) {
    return `https://${window.location.hostname}`;
  }

  return 'https://www.verizon.com';
};

const getPath = (flow = '') => {
  const url = window.location.href;
  if (url.includes('localhost') || url.includes('127.0.0.1')) {
    return ''; // relative path may change according to locally hosted folder from onevz-soe-aem-digital-homeinternet repo i) if from build/soe-home will be ''. ii) if from ui.apps then '/etc/clientlibs/vcg/home/soe-home'
  }
  if (flow === 'Ordering') {
    return '/inhome/static';
  }
  return '/etc/clientlibs/vcg/home/soe-home';
};

const prefetchAssets = (urls, predictedPage) => {
  urls?.forEach?.((jsPath) => {
    const newLink = document.createElement('link');
    newLink.rel = 'prefetch';
    newLink.href = getHostName(predictedPage) + jsPath;
    document.head.appendChild(newLink);
  });
};

export const PrefetchHomeInternet = () => {
  const url = `${getHostName()}${getPath()}/fetchassets1.js`;
  window.prefetchCallback = (data, predicted) => {
    prefetchAssets(data, predicted);
  };
  const script = document.createElement('script');
  script.setAttribute('src', url);
  document.body.appendChild(script);
};

/*Prefetch for Fios Ordering for other files*/
export const PrefetchFiosOrdering = () => {
  const url = `${getHostName()}${getPath('Ordering')}/fetchassets2.js`;
  window.prefetchCallback = (data, predicted) => {
    prefetchAssets(data, predicted);
  };
  const script = document.createElement('script');
  script.setAttribute('src', url);
  document.body.appendChild(script);
};

/*Prefetch for Fios Ordering for main files*/
export const PrefetchFiosOrderingCriticalAssets = () => {
  const url = `${getHostName()}${getPath('Ordering')}/fetchBPPCriticalAssets.js`;
  window.prefetchCallback = (data, predicted) => {
    prefetchAssets(data, predicted);
  };
  const script = document.createElement('script');
  script.setAttribute('src', url);
  document.body.appendChild(script);
};